import React, { useEffect, useState, useContext } from 'react'
import { country } from '../../helpers/getCountry';
import { get } from '../../api/requests/main';
import User from '../components/user';
import { post } from '../../api/requests/main';
import { Context } from '../../context/store';


function Users() {
    const [mainState, setMainState] = useContext(Context);
    const [load, setLoad] = useState(false);
    const [userId, setUserId] = useState(false);
    const [data, setData] = useState([]);
    const [defData, setDefData] = useState([]);

    useEffect(() => {
        if (!data.length) {
            getcalender()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // useEffect(() => {
    //     getcalender()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [pagCount])

    const getcalender = async (e) => {
        setLoad(true)
        try {
            const response = await get('list-users-50');
            if (response.status === 200) {
                setLoad(false)
                // setPag(Math.ceil(response.data.count / 10))
                setDefData(response.data.data.user);
                setData(response.data.data.user);
            }
        } catch (error) { setLoad(false) }
    }
    const viewUser = (s) => {
        setUserId(s)
    }

    const promiseOptions = async (inputValue) => {
        return new Promise(async (resolve) => {
            if (inputValue.target.value.length > 2) {
                try {
                    const response = await post('search-users', { "name": inputValue.target.value });
                    if (response.status === 200) {
                        setData(response.data.data);
                        // resolve(filterColors(response.data.data));
                    }
                } catch (error) {
                    // handle error
                }
            }
            if (inputValue.target.value.length === 0) {
                setData(defData);
            }
        });
    }

    const closePopupUser = (s) => {
        setUserId(false)
    }

    return (
        <>
            <div className='container'>
                <from>
                    <input type='text' className='form-control' placeholder='search for users by name, phone ' onChange={promiseOptions} />
                </from>
                <table className="table table-dark custom">
                    <thead>
                        <tr>
                            <th>Full Name</th>
                            <th>Phone Number</th>
                            <th>Country</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(a => {
                            return <tr onClick={(t) => setMainState({ type: "userId", payload: a.id })} key={a.id}>
                                <th>{a.name} {a.lastname}</th>
                                <td>{a.phone}</td>
                                <td>{country(a.nationality)}</td>
                                <td>{a.email ? <span className='success' >Checked-in &#x2713; </span> : <></>}</td>
                            </tr>
                        })}

                    </tbody>
                </table>

                <div className='row'>

                </div>
                {/* <ul className='pagination'>
                    {[...Array(pag)].map((_, i) => (
                        <li className={pagCount === i + 1 ? "acive btn " : "btn"}
                            disabled={pagCount === i + 1 ? true : false}
                            onClick={() => { setPagCount(i + 1) }} key={i}>{i + 1} </li>
                    ))}
                </ul> */}
            </div>
            {load ? <div className='load' > <span className='tar'></span></div> : <></>}

            {/* {userId ? <div className='popup'>
                <div className='child wide'>
                    <span onClick={closePopupUser}>x</span>
                    <User user={userId} auth={mainState.user} setState={setMainState} />
                </div>
            </div>

                : <></>} */}

        </>
    )
}

export default Users