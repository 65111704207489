import React, { useState, useRef, useEffect, useReducer, useContext } from 'react'
import { Notification } from '../../components/common/logic/Toggles';
import { Context } from '../../context/store';
import { getSelectedValuesFromNodeList } from '../../helpers/getSelectedValuesFromNodeList';
import { post } from '../../api/requests/main';
import Rooms from '../sub-componments/Rooms';
import { toast } from 'react-toastify';


import convertToArray from '../../helpers/getArr';


function MoveBooking({ bDate, userId, popup }) {
    const [disable, setDisable] = useState(false);
    const [total, setTotal] = useState('');
    const [hostelName, setHostelName] = useState('');
    const [mainState, setMainState] = useContext(Context);
    const formRef = useRef("");
    const list = convertToArray(process.env.REACT_APP_HOSTL)

    const updateTotal = () => {
        let me = 0;
        formRef.current.querySelectorAll('input[type=checkbox]:checked').forEach(element => {
            me += Number(element.getAttribute('cost'))
        });
        setTotal(me)
        return me
    }
    const updateInpt = (s) => {
        updateTotal();
    }

    const updateName = (s) => {
        setHostelName(s.target.querySelector('option:checked').getAttribute('name'))
    }

    const selectAll = (e) => {
        e.preventDefault();
        formRef.current.querySelectorAll('input[type=checkbox]').forEach(element => {
            element.checked = true;
        });
        updateTotal();
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        // setDisable(true)
        if (!formRef.current.querySelector('input[name=bookingDate]:checked')) {
            setDisable(false)
            toast.error('Select one day at least!')
            return;
        }

        let data = {
            "hostel_name": formRef.current.hostel_name.value,
            "uid_id": formRef.current.uid_id.value,
            "bedNumber": formRef.current.bedNumber.value,
            "hostel_endpoint": formRef.current.hostel_endpoint.value,
            "bookingDate": getSelectedValuesFromNodeList(formRef.current.bookingDate),
            "amount": formRef.current.amount.value,
            "user_id": formRef.current.user_id.value
        }

        try {
            const response = await post('shared/send-booking', data);
            if (response.status === 201) {
                toast('Booking send!')
                popup(false)
            }
        } catch (error) {
            toast.error('Something went wrong!')
        } finally {
            setDisable(false)
        }
    }

    useEffect(() => {
        for (let i = 0; i < list.length; i++) {
            const s = list[i];
            if (s.current === false) {
                setHostelName(s.name);
                break;
            }
        }

    }, []);

    return (
        <>
            <header>
                <h5> Move Booking </h5>
                <span onClick={() => popup(false)}><i className="fa-solid fa-xmark"></i></span>

            </header>
            <section>

            <form ref={formRef}>
                <input name='hostel_name' defaultValue={hostelName} type='hidden' />
                <input name='uid_id' type='hidden' defaultValue={mainState.user.id} />
                <div className='grid-50 gap'>
                    <lebs>Move to:
                        <select name='hostel_endpoint' className='form-select' onChange={updateName} >
                            {list.map((s) => {
                                if (s.current === false) {
                                    return <option key={s.name + "key"} value={s.link} name={s.name} >{s.name}</option>
                                }
                            })
                            }
                        </select>
                    </lebs>
                    <label>Bed Number:

                        <select className='form-select' name="bedNumber">
                            <Rooms />
                        </select>
                    </label>
                </div>
                <br />
                <div className='center'>
                    <button type="submit" onClick={selectAll} disabled={disable} className="btn btn-light">Select All</button>
                </div>
                <div className='grid-dates'>
                    {bDate.map(s => {
                        return <label className='btn'> <input value={s.id} onChange={updateInpt} type='checkbox' cost={s.amount} name='bookingDate' /> {s.bookingDate.substring(5)}</label>
                    })}
                </div>
                <input type='hidden' value={Number(total).toFixed(4)} name='amount' />
                <input type='hidden' value={userId} name='user_id' />
                Total Cost : <b>{Number(total).toFixed(2)}</b> AED
            </form>
            </section>
                
            <footer>
                    <button type="submit" onClick={handleSubmit} disabled={disable} className="btn btn-primary main">Move Booking</button>
                
            </footer>
                
        </>
    )
}

export default MoveBooking