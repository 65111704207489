import { useRef, useState } from 'react';
import { postFormData } from '../api/requests/main';
import { useNavigate } from "react-router-dom";

function Rules(a) {
    const [selectedLang, setSelectedLang] = useState('en');

    const lngs = [
        { code: 'en', nativeName: 'English' },
        { code: 'ru', nativeName: 'Russian' },
        { code: 'es', nativeName: 'Spanish' },
        { code: 'it', nativeName: 'Italian' },
        { code: 'ch', nativeName: 'Chinese' },
        { code: 'fr', nativeName: 'French' },
        { code: 'pr', nativeName: 'Portuguese' },
        { code: 'jp', nativeName: 'Japanese' },
        { code: 'gr', nativeName: 'Germany' },
    ];

    const updateLang = async (e) => {
        setSelectedLang(e)
    }

    return (
        <>
            <div>
                <header className="nop container">
                    <nav>
                        <div className="flex">
                            <span>
                                Language:
                            </span>
                            <select className="form-select" onChange={(a) => updateLang(a.target.value)} >
                                {lngs.map((lng) => {
                                    return (<option key={lng.code} value={lng.code} >{lng.nativeName} </option >);
                                })}
                            </select>
                        </div>
                    </nav>
                </header>
            </div >
            <div className='text-center print d-none print'>
                <span className='flex-print'>
                    <img alt='logo' className='headerLogo' width="60" src={process.env.REACT_APP_LOGO} />
                </span>
            </div>
            <div>
                {/* <div className='card'>
                    <h4 className='left'> {t('checkin')} </h4>
                    <ul>
                        {t('checkin_list', { returnObjects: true }).map((a, id) => (
                            <li key={id}>{a}</li>
                        ))}
                    </ul>
                </div>
                <div className='card'>
                    <h4 className='left'> {t('checkout')} </h4>
                    <ul>
                        {t('checkout_list', { returnObjects: true }).map((a, id) => (
                            <li key={id}>{a}</li>
                        ))}
                    </ul>
                </div>
                <div className='card'>
                    <div className='card-sm text-center'>
                        <h5 className=''>{t('rules')} </h5>
                        <h6 className=''>{t('rules_sub')} </h6>
                    </div>
                    <ul>
                        {t('rules_list', { returnObjects: true }).map((a, id) => (
                            <li key={id}>{a}</li>
                        ))}
                    </ul>
                </div>
                <p className='alert alert-warning'> {t('warn')} </p>
                <div className='text-end print d-none p-4'>
                    <span>
                        {t('signature')}: ______________
                    </span>
                </div> */}
            </div>
        </>
    );
}

export default Rules;
