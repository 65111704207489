import React, { useContext, useEffect, useState, useRef } from 'react'
import { Context } from '../../context/store';
import { getDates } from '../../api/requests/main';
import NewCheckin from './new-Checkin.js';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';


function Table() {
    const [mainState, setMainState] = useContext(Context);

    let data = mainState?.configurations?.rooms ? JSON.parse(mainState.configurations.rooms) : [];

    const [hoverId, setHoverId] = useState(null);
    const [hoverRid, setHoverRid] = useState(null);
    const [hoverDid, setHoverDid] = useState(null);
    const table = useRef();

    const today = new Date().toISOString().slice(0, 10);
    const fifteenDaysFromNow = new Date(today);
    const FourDaysTillNow = new Date(today);
    fifteenDaysFromNow.setDate(fifteenDaysFromNow.getDate() + 15);
    FourDaysTillNow.setDate(FourDaysTillNow.getDate() - 4);
    const startDate = FourDaysTillNow.toISOString().slice(0, 10);
    const endDate = fifteenDaysFromNow.toISOString().slice(0, 10);

    const [newBooking, setNewBooking] = useState(null);


    const [finalDate, setFinalDate] = useState([new Date(startDate), new Date(endDate)]);

    const initLogic = (event) => {
        let bookingId = event.target.getAttribute('b-id')
        let dateId = event.target.getAttribute('date-id')
        let roomId = event.target.getAttribute('room-id')
        if (bookingId) {
            setMainState({ type: 'bookingId', payload: bookingId })
        } else {
            let obj = {
                "bedNumber": roomId,
                "date": dateId
            }
            setNewBooking(obj)
        }
    }

    const closeInitLogic = (a) => {
        setNewBooking(false);
    }

    useEffect(() => {
        if (mainState.bookingId === false) {
            getcalender()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalDate, mainState.bookingId, mainState.reload])

    const getcalender = async () => {
        setMainState({ type: 'loader', payload: true })

        try {
            const response = await getDates({ start: new Date(finalDate[0]).toISOString().slice(0, 10), end: new Date(finalDate[1]).toISOString().slice(0, 10) });
            if (response.status === 200) {
                setMainState({ type: 'loader', payload: false })
                document.querySelectorAll('[room-id]').forEach((a) => {
                    a.innerHTML = " "
                    a.removeAttribute('i-id')
                    a.removeAttribute('bookingStatus')
                    a.removeAttribute('title')
                    a.removeAttribute('g-id')
                    a.removeAttribute('isPaid')
                    a.removeAttribute('b-id')
                })
                response.data.data.map((booking) => {
                    let tar = document.querySelector(`[room-id="${booking.bedNumber}"][date-id="${booking.bookingDate}"]`)
                    if (tar) {
                        let newDiv = document.createElement("div");
                        newDiv.setAttribute('i-id', booking.gid)
                        newDiv.setAttribute('g-id', booking.gid)
                        newDiv.setAttribute('bookingStatus', booking.bookingStatus)
                        newDiv.setAttribute('title', booking.bookingStatus)
                        newDiv.setAttribute('isPaid', "True")
                        newDiv.setAttribute('b-id', booking.bid)
                        // if (!table.current.querySelector(`[g-id="${booking.gid}"]`)){
                        newDiv.innerHTML += booking.name
                        // }
                        newDiv.setAttribute('room-id', booking.bedNumber)
                        newDiv.setAttribute('date-id', booking.bookingDate)
                        tar.appendChild(newDiv);
                    }
                    return ""
                })
            }
        } catch (error) {
        } finally {
        }
    };


    // helpers 
    function getDaysSelected() {
        const dates = [];
        const currentDate = new Date(finalDate[0]);
        const endDate = new Date(finalDate[1]);

        while (currentDate <= endDate) {
            dates.push(currentDate.toISOString().slice(0, 10));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    }

    function splitDate(dateString) {
        const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const date = new Date(dateString);
        const dayIndex = date.getDay();
        const day = daysOfWeek[dayIndex];
        const monthIndex = date.getMonth();
        const month = monthsOfYear[monthIndex];
        const dateOfMonth = day + " " + date.getDate();

        return { day, dateOfMonth, month };
    }


    const checkTarget = (t) => {
        setHoverRid(t.target.getAttribute('room-id'))
        setHoverDid(t.target.getAttribute('date-id'))
        if (t.target.getAttribute('g-id')) {
            setHoverId(t.target.getAttribute('g-id'))
        } else {
            setHoverId(null)
        }
    }
    return (
        <>
            <style> {` 
            .t-grid[h-id="${hoverId}"] 
             div[g-id="${hoverId}"][b-id][i-id]
             { background-color: #444444 !important; color: #fff; }
             .rom.r${hoverRid} {background:#34b2ff !important;color:#fff !important; transition: 0.1s; }
             [date-id="${hoverDid}"],
             [room-id="${hoverRid}"] { background:#e4e4e430 }
              
              `} </style>

            <div className='overFlow-mob'>

                <div className='container'>
                    <div className='flex'>
                        <h2>Main Calendar</h2>
                        <div className=' end'>
                            <DateRangePicker onChange={setFinalDate} value={finalDate} className="form-control" />
                        </div>
                    </div>
                </div>
                <input type='checkbox' id='showPrice' />
                <input type='checkbox' id='updateShow' />
                <section className='t-grid' onMouseOver={checkTarget} ref={table} h-id={hoverId}>
                    <div className='rooms'>
                        <div></div>
                        {(() => {
                            let c = 0;
                            return data.map((a, id) => (
                                <div key={id}>
                                    {a.beds.map((s, t) => {
                                        c = c + 1;
                                        return (
                                            <div className={`rom r${c} ${t === a.beds.length - 1 ? 'last' : ''}`} key={t}>
                                                {s}
                                            </div>
                                        );
                                    })}
                                </div>
                            ));
                        })()}
                    </div>
                    <div className='dates'>
                        {getDaysSelected().map((date, index) => (
                            <div key={index} className='date-container'>
                                <div className={date == today ? 'head--Date today' : "head--Date"}>
                                    <span>{splitDate(date).month}</span>
                                    <b>{splitDate(date).dateOfMonth}</b>

                                </div>
                                {(() => {
                                    let c = 0;
                                    return data.map((room, id) => (

                                        <div key={'room' + id}>
                                            {room.beds.map((s, t) => {
                                                const isLast = t === room.beds.length - 1;
                                                c = c + 1;
                                                return (
                                                    <div key={`${date}-${id}-${t}`}
                                                        r-id={id}
                                                        className={`room-container ${isLast ? 'last' : ''}`}
                                                    >

                                                        <div
                                                            r-id={c}
                                                            key={c + t + id + "sds"}
                                                            onDoubleClick={(a) => { initLogic(a) }}
                                                            room-id={c} date-id={date}
                                                        ></div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ));
                                })()}

                            </div>
                        ))}
                    </div>

                </section>
            </div>
            {
                newBooking ? (
                    <div className='popup adnew'>
                        <div className='child'>
                            <NewCheckin data={newBooking} popup={setNewBooking} closeInitLogic={closeInitLogic} />
                        </div>
                    </div>
                ) : (<></>)
            }
            <div className='tableFooter'>
                <div className='transfer color_b'>transfer</div>
                <div className='authorized color_b'>authorized</div>
                {/* <div className='checkIn color_b'>checkIn</div> */}
                {/* <div className='website color_b'>website</div> */}
                <div className='paid color_b'>paid</div>
                <div className='checkInPaid color_b'>checkInPaid</div>
                <div className='reserved color_b'>reserved</div>
            </div>
        </>
    )
}

export default Table