import { useContext, useEffect, useState, useRef } from 'react';
import { Context } from '../../../context/store';
import { post } from '../../../api/requests/main';
import * as total from "../../../helpers/getTotal";

import { letrs } from '../../../helpers/getById'
import { toast } from 'react-toastify';


import { PDFDownloadLink } from '@react-pdf/renderer';
import { Ebill } from '../../../pages/invoice/ebill';
// import { Notification } from '../../../components/common/logic/Toggles';


function ViewUser({ uid }) {
    const [mainState, setMainState] = useContext(Context);
    const [data, setUser] = useState(false);
    const usrUpdate = useRef();


    useEffect(() => {
        getUserInfo();
    }, []);



    const getUserInfo = async () => {
        setMainState({ type: "loader", payload: true });

        try {
            const response = await post("get-user-by-id", { id: uid });
            if (response.status === 200) {
                setUser(response.data.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setMainState({ type: "loader", payload: false });
        }
    };

    const updateGuest = async () => {
        try {
            const response = await post('guests/guest-edit', usrUpdate.current);
            toast('Guest updated!')
        } catch (error) { console.error(error); }
    }


    if (!data) {
        return <>
            <div className='popup'>
                <div className='child wide'>
                    Loading
                </div>
            </div>
        </>
    }

    return (
        <>
            <div className='popup'>
                <div className='child wide'>
                    <header className='flex'>
                        <h4>User Info <small> {data.user.name} {data.user.lastname}</small></h4>
                        <span onClick={() => { setMainState({ type: 'userId', payload: false }) }}><i className="fa-solid fa-xmark"></i></span>
                        {mainState.user.is_staff == '1' && data.passport.image !== "" ?
                            <h4 style={{ textAlign: "center" }}>
                                <PDFDownloadLink document={<Ebill dat={data} />} fileName={data.user.name + data.user.lastname + " - invoice.pdf"}>
                                    {({ blob, url, loading, error }) =>
                                        loading ? 'Loading document...' : 'Download Invoice.'
                                    }
                                </PDFDownloadLink>
                            </h4>
                            : <></>}
                    </header>

                    <section>
                        <div className='card dark'>
                            <div className='grid-50'>
                                <form ref={usrUpdate} >
                                    <input type='hidden' value={data.user.id} name='id' />
                                    <div> First Name : <input className="form-control" name='name' type='text' defaultValue={data.user.name} /> </div>
                                    <div> Last Name : <input className="form-control" name='lastname' type='text' defaultValue={data.user.lastname} /> </div>

                                    <div> E-mail : <input className="form-control" disabled={data.user.verified === "True" ? true : false} name='email' type='email' defaultValue={data.user.email} /> </div>

                                    <div> Phone :
                                        <input className="form-control" name='phone' type='number' defaultValue={data.user.phone} /> </div>
                                    <br />
                                </form>


                            </div>
                        </div>
                        <div className='flex space'>
                            {data.passport.image === "" ? <>NO PASSPORT</> :
                                <span className='rot-img'>
                                    <img className='sm-pas' src={mainState.configurations.bucket + "/passport/" + data.passport.image} />
                                </span>
                            }
                            <img className='sm-ps' src={data.user.signature} />
                        </div>
                        {data.payments.length ?
                            <div className='card dark cloaps'>
                                <label htmlFor='Payment'>Payments <h5>{Number(total.payment(data.payments)).toFixed(2)}</h5> </label>
                                <input type='checkbox' id='Payment' />
                                <div className='sm-table dark card-in'>
                                    <table className='table'>
                                        <tr>
                                            <th>Payment</th>
                                            <th>Type</th>
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                        </tr>
                                        {data.payments.map((a, id) => {
                                            return <tr key={'booking' + id} >
                                                <th>{a.name}</th>
                                                <th>{a.paymentMethod}</th>
                                                <th>{(new Date(a.date)).toLocaleDateString()}</th>
                                                <th className='description' title={a?.description}> {a?.description}</th>
                                                <th className={a.type}>{Number(a.amount).toFixed(1)}</th>
                                            </tr>

                                        })}
                                    </table>
                                </div>
                            </div> :
                            <div className='card dark empty'>
                                <h5>No Payments </h5>
                            </div>
                        }
                        {data.deposit.length ?
                            <div className='card dark cloaps'>
                                <label htmlFor='Deposit'>Deposits
                                    <h5>{Number(total.deposit(data.deposit)).toFixed(2)}</h5>

                                </label>
                                <input type='checkbox' id='Deposit' />
                                <div className='sm-table dark card-in'>
                                    <table className='table'>
                                        <tr>
                                            <th>Type</th>
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                        </tr>
                                        {data.deposit.map((a, id) => {
                                            return <tr key={'booking' + id} >
                                                <th>{a.paymentMethod}</th>
                                                <th>{(new Date(a.date)).toLocaleDateString()}</th>
                                                <th className='description' title={a?.description}> {a?.description}</th>
                                                <th className={a.type}>{Number(a.amount).toFixed(1)}</th>
                                            </tr>
                                        })}
                                    </table>
                                </div>
                            </div> :
                            <div className='card dark empty'>
                                <h5>No Deposits </h5>
                            </div>
                        }
                        {data.bookingDate.length ?
                            <div className='card dark cloaps'>
                                <label htmlFor='Booking'>Booking
                                    <h5>{Number(total.booking(data.bookingDate)).toFixed(2)}</h5>

                                </label>
                                <input type='checkbox' id='Booking' />
                                <div className='sm-table dark card-in'>
                                    <table className='table'>
                                        <tr>
                                            <th>Bed</th>
                                            <th>Date</th>
                                            <th>Platform</th>
                                            <th>Amount</th>
                                        </tr>
                                        {data.bookingDate.map((a, id) => {
                                            return <tr key={'booking' + id} >
                                                <th>{letrs[a.bedNumber]}</th>
                                                <th>{a.bookingDate}</th>
                                                <th>{a.platform}</th>
                                                <th>{a.amount}</th>
                                            </tr>
                                        })}
                                    </table>
                                </div>
                            </div> :
                            <div className='card dark empty'>
                                <h5>No Booking </h5>
                            </div>
                        }
                    </section>

                    <footer>
                        <p className='btn btn-primary'
                            onClick={updateGuest}
                        > Update User </p>
                    </footer>

                </div>
            </div>
        </>
    );
}

export default ViewUser;
