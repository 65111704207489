import { useState } from 'react';


function AddNewDeposit({ setnewDeposit, SubMethod, mainState, uid, saveDeposit, DepositRef }) {
    const [amount, setAmount] = useState('');

    return (
        <>
            <div className="popup ">
                <div className="card child dark">
                    <header>
                        <h4> Add new Deposits </h4>
                        <span onClick={() => { setnewDeposit(false); }} > <i className="fa-solid fa-xmark"></i> </span>
                    </header>
                    <section>
                        <form ref={DepositRef}>
                            Amount:
                            <input type="number" onWheel={(e) => e.target.blur()} onChange={(s) => { setAmount(s?.target?.value) }} className="form-control" name="amount" placeholder="amount" />
                            Type:
                            <select name="type" className="form-select" defaultValue="income">
                                <option value="income">Income</option>
                                <option value="outgoing">Outgoing</option>
                            </select>
                            Deposit Method:
                            <select className="form-select" name="paymentMethod"> <SubMethod /> </select>
                            Description:
                            <textarea className="form-control" name="description" placeholder="Deposits Description" ></textarea>
                            <input name="date" type="hidden" className="form-control" defaultValue={new Date().toISOString().substr(0, 10)} />
                            <input name="uid_id" type="hidden" defaultValue={mainState.user.id} />
                            <input name="gid_id" type="hidden" defaultValue={uid} />
                        </form>
                    </section>
                    <footer className='actions'>
                        <button className="btn btn-primary main" onClick={saveDeposit} disabled={!amount} > Save Deposit </button>
                    </footer>
                </div>
            </div>
        </>
    )
}

export default AddNewDeposit;