import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { useEffect, useContext, useState } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import NewGuest from "./pages/public/newGuest";
import NewPassport from "./pages/public/newPassport";
import Main from "./main.js";
import Notifi from './components/common/noti/notification.js'
import Loader from './components/common/noti/loader.js'
import Table from './pages/public/table';
import Cash from './pages/public/cash';
import Expenses from './pages/public/expenses';
import Vcards from './pages/public/vcards';
import WaitListWebsite from './pages/public/waitList_website';
import ChannelManager from './pages/public/ChannelIframe.js';
import GPT from './pages/public/gpt';
import PrivateRoutes from './utilities/constants/protected';
import Users from './pages/public/users';
import Payment from './pages/public/payment';
import Login from './pages/Login.js';
import BookingUpdates from './pages/BookingUpdates.js';
import Rules from './pages/Rules.js';
import DoorKey from './pages/DoorKey.js';
import Stripe from './pages/Stripe.js';
import Bookinglist from './pages/public/bookingList';
import AutoBooking from './pages/AutoBooking.js';
import Pricing from './pages/Pricing.js';
import Dashboard from './pages/Dashboard.js';
import { Context } from './context/store';
import Chat from './pages/public/Chat';
import { requestNotificationPermission, messaging } from "./firebase";
import { post } from './api/requests/main.js';
import { onMessage } from 'firebase/messaging';
import ViewBooking from './components/common/popups/viewBooking.js';
import ViewUser from './components/common/popups/viewUser.js';
import 'react-loading-skeleton/dist/skeleton.css'

function App() {
  const [mainState] = useContext(Context);
  const [fireBaseToken, setFireBaseToken] = useState(false);
  const [fbNoti, setFbNoti] = useState(false);

  const backgroundColor = process.env.REACT_APP_BACKGROUND_COLOR || 'black';
  useEffect(() => {
    document.title = process.env.REACT_APP_NAME + " - Booking CRM";

    const svgCode = generateSVG(process.env.REACT_APP_NAME.charAt(0));
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = `data:image/svg+xml,${encodeURIComponent(svgCode)}`;
    }
    if (process.env.REACT_APP_DEBUG !== "True") {
      const fetchData = async () => {
        try {
          const token = await requestNotificationPermission();
          setFireBaseToken(token)
        } catch (error) {
        }
      };

      fetchData();
    }
  }, []);
  const generateSVG = (letter) => {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
        <style>
            text { font-family: Arial, sans-serif; fill: ${process.env.REACT_APP_COLOR}; }
        </style>
        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="100">${letter}</text>
      </svg>
    `;
  };

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG !== "True") {
      if (fireBaseToken && mainState.user.id) {
        post('firebase/token', { id: mainState.user.id, token: fireBaseToken })
      } else {
      }
    }
  }, [fireBaseToken, mainState]);

  useEffect(() => {
    let { title, body } = fbNoti
    const showNotification = async () => {
      if ('Notification' in window) {
        if (Notification.permission !== 'granted') {
          await Notification.requestPermission();
        }
        if (Notification.permission === 'granted') {
          if (body && body) {
            let notification = new Notification(title, {
              body, data: {
                link: process.env.REACT_APP_DOMAIN.replace('api.', 'booking.') + 'chat'
              }
            });
            notification.onclick = function (event) {
              const link = event.target.data.link;
              if (link) {
                window.open(link);
              }
            };
          }
        }
      }
    };

    showNotification();
  }, [fbNoti]);



  onMessage(messaging, (payload) => {
    setFbNoti(payload.notification)
  });


  return (
    <main style={{ backgroundColor }} className={mainState.scroll ? "" : "noScroll"} >
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/rules' element={<Rules />} />
          <Route path='/stripe' element={<Stripe />} />
          <Route path='/user/auto-booking' element={<AutoBooking />} />
          {/* <Route path='/user/auto-checkin' element={<AutoCheckin />} /> */}
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Main />} >
              <Route path="/" element={<Dashboard />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/add-new-guest" element={<NewGuest />} />
              <Route path="/booking-logs" element={<BookingUpdates />} />
              <Route path="/check-in-new-guest" element={<NewPassport />} />
              <Route path="/table" element={<Table />} />
              <Route path="/chat" element={<Chat />} />
              <Route path="/users" element={<Users />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/current-cash" element={<Cash />} />
              <Route path="/expenses" element={<Expenses />} />
              <Route path="/booking-list" element={<Bookinglist />} />
              <Route path="/waitlist-website" element={<WaitListWebsite />} />
              <Route path="/channel-manager" element={<ChannelManager />} />
              <Route path="/v-cards" element={<Vcards />} />
              <Route path="/gpt" element={<GPT />} />
              <Route path="/door-key" element={<DoorKey />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
      <Notifi />
      <Loader />

      {/* Popups */}
      {mainState.bookingId ? <ViewBooking bid={mainState.bookingId} mainState={mainState} /> : false}
      {mainState.userId ? <ViewUser uid={mainState.userId} /> : false}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

    </main>
  );
}

export default App;