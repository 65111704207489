export const temp = {
    "Alphatel": {
        "ourLocation": `Our hostel is located on the 32th floor, unit number 3201 Rimal 5 building- JBR area.
        https://maps.app.goo.gl/jmWBwq2XNo1RTaUWA` ,
        "welcome": ` This is Alphatel Hostel reaching out to you. Could you please inform us of your preferred check-in time?`
    }, "Travelers Hostel": {
        "ourLocation": `Our hostel is located on the 11th floor, unit number 1101 Rimal 4 building- JBR area.
https://maps.app.goo.gl/uoLdbcmSMZdLWZCeA`,
        "welcome": ` This is Travelers Hostel reaching out to you. Could you please inform us of your preferred check-in time?`
    },
    "Rovers Hostel": {
        "ourLocation": `Our hostel is located on the 30th floor, unit number 3002 Rimal 5 building- JBR area.
 https://maps.app.goo.gl/NzVGJjrbej287FwN7` ,
        "welcome": ` This is Rovers Hostel reaching out to you. Could you please inform us of your preferred check-in time?`

    }
}