import React, { useRef, useEffect, useState, useContext } from "react";
import { sendMessage } from "../../api/requests/main";
import { Context } from "../../context/store";
import { post } from "../../api/requests/main";
import { temp } from '../../helpers/templateMsg/template'

const SingleChat = ({ phoneId, userData, setUserData, userName }) => {
  const [mainState, setMainState] = useContext(Context);

  const msgInput = useRef()
  const containerRef = useRef(null);
  // const [username, setUsername] = useState(false);
  const [load, setLoad] = useState(false);
  const [customer, setCustomer] = useState(false);
  const [text, setText] = useState('');

  const handleInputChange = (event) => {
    setText(event.target.value);
  };

  const adjustTextareaHeight = () => {
    const textarea = document.getElementById('expandingTextarea');
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight - 20}px`;
  };



  function formatDateOrTime(inputDateStr) {
    const inputDate = new Date(inputDateStr);
    const currentDate = new Date();

    if (
      inputDate.getDate() === currentDate.getDate() &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear()
    ) {
      // The input date is today, so return the time
      const hours = inputDate.getHours().toString().padStart(2, '0');
      const minutes = inputDate.getMinutes().toString().padStart(2, '0');
      const seconds = inputDate.getSeconds().toString().padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    } else {
      // The input date is not today, so return the full date
      const year = inputDate.getFullYear();
      const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
      const day = inputDate.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  }

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      const lastMessage = container.lastElementChild;

      if (lastMessage) {
        lastMessage.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      getCustomer()
    }

  }, [userData]);


  const TemplateMsg = async (e) => {
    msgInput.current.value = temp[process.env.REACT_APP_NAME][e]
  }
  const WpTemplateMsg = async (e) => {
    const userConfirmation = window.confirm("Are you sure you want to send this WhatsApp template?");

    if (userConfirmation) {
      const response = await post("whatsapp/send-template", { template: e, phone: phoneId });
      if (response.status === 200 || response.status === 201) {
        alert("Template Send");
      } else {
        alert("Something Happend!");
      }
    } else {
    }
  }

  const getCustomer = async (e) => {
    const response = await post("get-user-by-phone", { phone: phoneId })
    setCustomer(response.data)
  }

  const loadImage = async (e) => {
    const response = await post("whatsapp/load-assets", { id: e.target.id })
    e.target.src = "data:image/jpeg;base64," + response.data.img_base64
  }
  const loadAudio = async (e) => {
    if (e.target.innerHTML.includes('Click to Play')) {
      const response = await post("whatsapp/load-assets", { id: e.target.id })
      e.target.innerHTML = '<audio controls src="data:audio/ogg;base64,' + response.data.img_base64 + '" ></audio>'
    }
  }
  const handleSendMessage = async (e) => {
    e.preventDefault()
    try {
      setLoad(true)
      const response = await sendMessage({
        user: { id: mainState.user.id },
        "message": msgInput.current.value,
        "phone": phoneId
      });
      if (response.status === 201) {
        setUserData(response.data)
        msgInput.current.value = ''
        setText('')
      }
      setLoad(false)
    } catch (error) {
      alert(error)
      setLoad(false)
    }
  }

  return (
    <div className="message-area">
      {userData ?
        <>
          <header>
            <div>
              {userName}
              {customer?.id ?
                <b className='a' onClick={() => { setMainState({ type: 'userId', payload: customer?.id }) }}><i className="fa-solid fa-arrow-up-right-from-square"></i> </b>
                : false
              }
            </div>
            <span>
              <i className="fa-solid fa-ellipsis"></i>
              <section>
                <div onClick={() => { TemplateMsg("ourLocation") }}> Our Location </div>
                <hr />
                <div className="templateMsg" onClick={() => { WpTemplateMsg("arrival") }}> Check Arrival </div>
                <div className="templateMsg" onClick={() => { WpTemplateMsg("welcome") }}> Welcome </div>
                <div className="templateMsg" onClick={() => { WpTemplateMsg("our_service") }}> Our Service </div>
              </section>
            </span>
          </header>
          <form className="messagesForm" onSubmit={(e) => handleSendMessage(e)}>
            <textarea ref={msgInput} className="message" type="text" placeholder="Type a message"

              id="expandingTextarea"
              value={text}
              onChange={handleInputChange}
              onInput={adjustTextareaHeight}

            ></textarea>
            {load ?
              <>....</>
              :
              <button type="submit">
                <i className="fa fa-paper-plane"></i>
              </button>
            }
          </form>
        </>
        : <></>
      }
      <div className="messages-container" ref={containerRef}>
        {userData.map((el, id) => {
          if (el.message_type == "text") {
            return (
              <div key={id} className="singleMessage">
                <p className={el.messaging_product === 'whatsapp' ? 'admin messages' : 'messages'}>
                  <span>
                    {el.message_body}
                  </span>
                  <span className="time">{formatDateOrTime(el.created_at)}
                    {el.messaging_product !== 'whatsapp' ?
                      <span className="status" readStatus={el.read_status} >
                        {el.read_status === 'sent' ? <i className="fa-solid fa-check"></i> : null}
                        {el.read_status === "failed" ? (<i className="fa-solid fa-x"></i>) : null}
                        {el.read_status === "read" || el.read_status === 'delivered' ? <> <i className="fa-solid fa-check"></i> <i className="fa-solid fa-check"></i> </> : null}
                      </span>
                      : <></>}
                  </span>
                </p>
              </div>
            )
          } else if (el.message_type == "contacts") {
            // let message_body = 
            const body = JSON.parse(el.message_body.replace(/'/g, '"'));
            return (
              <div key={id} className="singleMessage">
                <p className={el.messaging_product === 'whatsapp' ? 'admin messages' : 'messages'}>
                  {body.map((contact, t) => {
                    return (<span key={t}>
                      first_name:{contact.name.first_name} <br />
                      formatted_name:{contact.name.formatted_name} <br />
                      last_name:{contact.name.last_name} <br />
                    </span>)
                  })}
                </p>
              </div>)
          } else if (el.message_type == "image") {
            let stringWithoutSingleQuotes = el.message_body.replace(/"([^'"]*)'/g, '"$1');
            // FUCK YOU MARK 
            stringWithoutSingleQuotes = stringWithoutSingleQuotes.replace(' mime_type', " 'mime_type")
            const body = JSON.parse(stringWithoutSingleQuotes.replace(/'/g, '"'));

            return (
              <div key={id} className="singleMessage">
                <p className={el.messaging_product === 'whatsapp' ? 'admin messages' : 'messages'}>
                  <img src="#" onClick={loadImage} id={body.id} alt="Click to view" />
                  {body.caption ? <> <br /> {body.caption}</> : <></>}
                </p>
              </div>)
          } else if (el.message_type == "audio") {
            let datt = el.message_body.replace(/'/g, '"')
            datt = datt.replace('True', '"True"');
            const body = JSON.parse(datt);

            return (
              <div key={id} className="singleMessage">
                <p className={el.messaging_product === 'whatsapp' ? 'admin messages' : 'messages'}>
                  <span onClick={loadAudio} id={body.id} >Click to Play </span>
                  {body.caption ? <> <br /> {body.caption}</> : <></>}

                </p>
              </div>)
          }
        })}
      </div>
    </div>
  );
};

export default SingleChat;
