export default function convertToArray(string) {
    const dataArray = string.split(';');
    const array = dataArray.map(data => {
        const values = data.split(':');
        const obj = {
            name: values[0],
            link: `https://${values[1]}/api/v1`,
            current: values[2] === 'true'
        };
        return obj;
    });
    return array;
}