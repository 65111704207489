import React, { useEffect, useState } from "react";
import { listChats, getSingleChat, sendMessage } from "../../api/requests/main";
import { formatDate } from "../../helpers/dateFormat";
import SingleChat from "./SingleCHat";


import { useLocation } from 'react-router-dom';


const Chat = () => {
  const [chatList, setChatList] = useState([])
  const [userData, setUserData] = useState(false)
  const [userName, setUserName] = useState(false)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const phone = searchParams.get('phone');
  const [phoneId, setPhoneId] = useState(phone || 0)

  function removeDuplicatePhones(data) {
    const uniquePhones = data.reduce((unique, item) => {
      if (!unique[item.phone]) {
        unique[item.phone] = item;
      }
      return unique;
    }, {});
    let array = Object.values(uniquePhones);
    return array.sort((a, b) => new Date(b.time) - new Date(a.time));
  }

  const getAllChats = async () => {
    try {
      const response = await listChats();
      if (response.status === 200) {
        setChatList(removeDuplicatePhones(response.data));
        return response
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    getAllChats();
  }, [])


  useEffect(() => {
    handleSingleChatDetails()
  }, [phoneId, setPhoneId])

  const handleSingleChatDetails = async () => {
    try {
      const response = await getSingleChat(phoneId);
      if (response.status === 200) {
        setUserName(response.data.user);
        setUserData(response.data.chat);
      }
    } catch (error) {
      setUserData(false)
    }

  }


  return (
    <div className="chat-body">
      <div className="chat-window">

        <div className="conversations">
          {chatList.map((user) => {
            return (
              <div className={user.phone === phoneId ? "chat-details active" : "chat-details"} onClick={() => { setPhoneId(user.phone) }}>
                <span>{user.fullname}</span>
                <span className="time">{formatDate(user.time)}</span>
              </div>
            );
          })}
        </div>
        {userData && (
          <SingleChat phoneId={phoneId} userData={userData}  setUserData={setUserData} userName={userName} />
        )}
      </div>
    </div>
  );
};

export default Chat;
